.cfra-ag-grid {
    .ag-theme-alpine.small-grid-theme {
        .etfs-cell .fund_profile_composite_ticker {
            .fund_profile_composite_ticker {
                display: flex;
                align-items: center;
                a {
                    line-height: 32px;
                    color: #000;
                    font-family: GraphikRegular;
                }
            }

            .fund_profile_composite_name {
                color: #000;
                font-family: GraphikMedium;
                line-height: 32px;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        .price-cell .ag-cell-value {
            color: #00a76f;
        }
    }
}
