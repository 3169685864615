.cfra-ag-grid {
    .ag-theme-alpine.small-grid-theme {
        --rows-background-color: rgba(242, 245, 252, 0.5);

        .ag-header {
            border-top: none;
            border-bottom: none;
        }

        // header cells padding
        .ag-cell-label-container {
            padding-top: 0px;
            padding-bottom: 10px;
        }

        // resize elements in header
        .ag-header-container .ag-header-cell-resize {
            width: 15px;
        }
        .ag-header-cell .ag-header-cell-resize::after {
            background-color: #fff;
            height: 100%;
            top: 0;
            width: 100%;
        }

        // header
        --ag-header-background-color: #fff;
        --ag-header-foreground-color: #000; // font color
        --ag-header-font-family: GraphikMedium;

        .ag-header-cell-text {
            font-size: 16px;
        }

        // rows
        .ag-row {
            font-family: GraphikMedium;
            color: #2e2e36;
            font-size: 14px;
            background-color: var(--rows-background-color);
            border-bottom: 0px;

            // align content center vertically
            .ag-cell {
                display: flex;
                align-items: center;

                .ag-cell-wrapper {
                    display: grid;
                }
            }

            .ag-cell-value {
                line-height: 32px;
            }
        }

        // row styles on hover
        .ag-row-hover::before {
            box-shadow: 0px 4px 9px 0px rgba(0, 0, 0, 0.08);
        }

        .ag-pinned-left-cols-container > .ag-row {
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
        }

        .ag-center-cols-container > .ag-row {
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
        }

        // disable hover and selection colors
        --ag-row-hover-color: transparent;
        --ag-selected-row-background-color: transparent;
        --ag-alpine-active-color: transparent;
    }
}
